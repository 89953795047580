<template>
  <BasicModal
    action-title="Update"
    :action="submitForm"
    title="Update Patch name"
    :close-on-action="false"
    :disable-action-button="form.isLoading || !isFormValid"
  >
    <FormSubmitErrors :formName="formName" />
    <FormInput :formName="formName" fieldName="name" ref="focusElement" />
    <LoadingOverlay v-if="form.isLoading" />
  </BasicModal>
</template>

<script>

  import useForm from '@/composables/useForm'

  import FormInput from '@/components/forms/FormInput.vue'
  import LoadingOverlay from '@/components/utils/LoadingOverlay.vue'
  import FormSubmitErrors from '@/components/forms/FormSubmitErrors.vue'

  import BasicModal from '@/components/modals/_BasicModal.vue'

  export default {
    components: {
      FormInput,
      BasicModal,
      LoadingOverlay,
      FormSubmitErrors,
    },
    props: {
      patch: {
        type: Object,
        required: true,
      },
      onSuccess: {
        type: Function,
        required: true,
      },
    },
    setup() {

      // data
      const formName = 'renamePatchForm'

      // composables
      const { form, isFormValid, focusElement } = useForm({ formName })

      return {
        form,
        formName,
        isFormValid,
        focusElement,
      }

    },
    created() {
      this.$store.dispatch('forms/UPDATE_FIELD', {
        fieldName: 'name',
        formName: this.formName,
        newValue: this.patch.name,
        newPlaceholder: this.patch.name,
      })
    },
    methods: {
      close() {
        this.$store.dispatch('modals/CLOSE_MODAL')
      },
      submitForm() {

        this.$store.commit('forms/SET_SUBMIT_ENDPOINT', { formName: this.formName, newSubmitEndpoint: `/patches/${this.patch.id}` })

        return this.$store.dispatch('forms/SUBMIT_FORM', this.formName)
          .then((response) => {
            return this.$store.dispatch('pagination/UPDATE_ITEM_IF_IN_CURRENT_PAGE', { listName: 'userPatches', updatedItem: response.data })
              .finally(() => {
                this.onSuccess(response.data)
                this.close()
              })
          })
          .catch(() => {
            // do nothing, template will show error
          })

      },
    },
  }

</script>

<style lang="stylus" scoped>

</style>
